import { Currency } from "../types";
import { defineMessage } from "react-intl";

export const currencies: Currency[] = [
  {
    code: "EUR",
    name: defineMessage({
      id: "currency-eur",
      defaultMessage: "Euro",
    }),
  },
  {
    code: "AUD",
    name: defineMessage({
      id: "currency-aud",
      defaultMessage: "Australian Dollar",
    }),
  },
  {
    code: "CAD",
    name: defineMessage({
      id: "currency-cad",
      defaultMessage: "Canadian Dollar",
    }),
  },
  {
    code: "CHF",
    name: defineMessage({
      id: "currency-chf",
      defaultMessage: "Swiss Frank",
    }),
  },
  {
    code: "CZK",
    name: defineMessage({
      id: "currency-czk",
      defaultMessage: "Czech Koruna",
    }),
  },
  {
    code: "DKK",
    name: defineMessage({
      id: "currency-dkk",
      defaultMessage: "Danish Krone",
    }),
  },
  {
    code: "GBP",
    name: defineMessage({
      id: "currency-gbp",
      defaultMessage: "British Pound",
    }),
  },
  {
    code: "HKD",
    name: defineMessage({
      id: "currency-hkd",
      defaultMessage: "Hong Kong Dollar",
    }),
  },
  {
    code: "HUF",
    name: defineMessage({
      id: "currency-huf",
      defaultMessage: "Hungarian Forint",
    }),
  },
  {
    code: "ILS",
    name: defineMessage({
      id: "currency-ils",
      defaultMessage: "Israeli New Shekel",
    }),
  },
  {
    code: "JPY",
    name: defineMessage({
      id: "currency-jpy",
      defaultMessage: "Japanese Yen",
    }),
  },
  {
    code: "NOK",
    name: defineMessage({
      id: "currency-nok",
      defaultMessage: "Norwegian Krone",
    }),
  },
  {
    code: "NZD",
    name: defineMessage({
      id: "currency-nzd",
      defaultMessage: "New Zealand Dollar",
    }),
  },
  {
    code: "PHP",
    name: defineMessage({
      id: "currency-php",
      defaultMessage: "Philippine Piso",
    }),
  },
  {
    code: "PLN",
    name: defineMessage({
      id: "currency-pln",
      defaultMessage: "Polish Złoty",
    }),
  },
  {
    code: "RUB",
    name: defineMessage({
      id: "currency-rub",
      defaultMessage: "Russian Ruble",
    }),
  },
  {
    code: "SEK",
    name: defineMessage({
      id: "currency-sek",
      defaultMessage: "Swedish Krona",
    }),
  },
  {
    code: "SGD",
    name: defineMessage({
      id: "currency-sgd",
      defaultMessage: "Singapore Dollar",
    }),
  },
  {
    code: "USD",
    name: defineMessage({
      id: "currency-usd",
      defaultMessage: "US Dollar",
    }),
  },
];
