import React from "react";
import clsx from "clsx";
import styles from "./styles.module.css";
import { ColorScheme } from "../../types";

export interface HeadingProps {
  children?: React.ReactNode;
  className?: string;
  as?: React.ElementType | string;
  colorScheme?: ColorScheme;
}

export function H1({
  as: Component = "h1",
  className,
  colorScheme,
  ...props
}: HeadingProps) {
  return (
    <Component
      className={clsx(
        styles["level-1"],
        colorScheme && styles[`${colorScheme}-scheme`],
        className
      )}
      {...props}
    />
  );
}

export function H2({
  as: Component = "h2",
  className,
  colorScheme,
  ...props
}: HeadingProps) {
  return (
    <Component
      className={clsx(
        styles["level-2"],
        colorScheme && styles[`${colorScheme}-scheme`],
        className
      )}
      {...props}
    />
  );
}

export function H3({
  as: Component = "h3",
  className,
  colorScheme,
  ...props
}: HeadingProps) {
  return (
    <Component
      className={clsx(
        styles["level-3"],
        colorScheme && styles[`${colorScheme}-scheme`],
        className
      )}
      {...props}
    />
  );
}
