import { defineMessages } from "react-intl";

const messages = defineMessages({
  namePlaceholder: {
    id: "name-placeholder",
    defaultMessage: "Your company",
  },
  paymentMethodCreditCard: {
    id: "payment-method-credit-card",
    defaultMessage: "Credit Card",
  },
  continue: {
    id: "continue",
    defaultMessage: "Continue",
  },
  close: {
    id: "close",
    defaultMessage: "Close",
  },
  startOver: {
    id: "start-over",
    defaultMessage: "Start over",
  },
  title: {
    id: "title",
    defaultMessage: "Explore Mollie Checkout",
  },
  noResultsFound: {
    id: "no-results-found",
    defaultMessage: "No results found",
  },
});

export default messages;
