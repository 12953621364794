import React from "react";
import clsx from "clsx";
import styles from "./styles.module.css";

export interface StackProps {
  children?: React.ReactNode;
  className?: string;
  alignment?: "leading" | "center" | "trailing" | "space-between";
  spacing?: "sm" | "md" | "lg" | "xl";
  as?: React.ElementType | string;
}

export function HStack({
  className,
  alignment = "center",
  spacing = "sm",
  as: Component = "div",
  ...props
}: StackProps) {
  return (
    <Component
      className={clsx(
        styles.hstack,
        styles[alignment],
        styles[`spacing-${spacing}`],
        className
      )}
      {...props}
    />
  );
}

export function VStack({
  className,
  alignment = "center",
  spacing = "sm",
  as: Component = "div",
  ...props
}: StackProps) {
  return (
    <Component
      className={clsx(
        styles.vstack,
        styles[alignment],
        styles[`spacing-${spacing}`],
        className
      )}
      {...props}
    />
  );
}
