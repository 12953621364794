import { FormattedMessage } from "react-intl";
import Toolbar from "../../Toolbar";
import { H1, H2 } from "../../Heading";
import { Text } from "../../Text";
import styles from "../styles.module.css";
import { VStack } from "../../Stack";
import NavButtons from "../NavButtons";
import LocaleSelect from "../LocaleSelect";
import { Button } from "@mollie/react-components";
import appMessages from "../../../messages";
import messages from "../messages";
import FadeIn from "../../FadeIn";

function Home() {
  return (
    <div className={styles.home}>
      <Toolbar>
        <FadeIn>
          <Button as="a" href="https://www.mollie.com/" size="small">
            mollie.com
          </Button>
        </FadeIn>
      </Toolbar>

      <VStack alignment="space-between" spacing="xl" className={styles.content}>
        <FadeIn delay={40}>
          <VStack>
            <H1>
              <FormattedMessage {...appMessages.title} />
            </H1>

            <Text as="p">
              <FormattedMessage
                id="sidebar-home-text"
                defaultMessage="Checkout turns a simple, standard payment form into a professional, on-brand experience that inspires trust and drives conversions. All without having to write a single line of code."
              />
            </Text>
          </VStack>
        </FadeIn>

        <FadeIn delay={80}>
          <VStack spacing="lg">
            <H2 colorScheme="light">
              <FormattedMessage {...messages.paymentTypeNavTitle} />
            </H2>

            <NavButtons />
            <LocaleSelect />
          </VStack>
        </FadeIn>
      </VStack>
    </div>
  );
}

export default Home;
