import {
  PaymentType,
  PaymentStatus,
  Action,
  View,
  BackgroundImage,
} from "./types";
import { IDealBank, PaymentMethod } from "../../types";

interface ISelectPaymentType {
  paymentType: PaymentType;
  methods: string;
}

const selectOneOffPayments = (payload: string): Action<ISelectPaymentType> => ({
  type: "SET_PAYMENT_TYPE",
  payload: {
    paymentType: "one-off-payments",
    methods: payload,
  },
});

const selectRecurringPayments = (
  payload: string
): Action<ISelectPaymentType> => ({
  type: "SET_PAYMENT_TYPE",
  payload: {
    paymentType: "recurring-payments",
    methods: payload,
  },
});

const setCompanyName = (payload: string): Action<string> => ({
  type: "SET_NAME",
  payload,
});

interface ISetLocationType {
  location: string;
  methods: string;
}

const setLocation = (
  location: string,
  methods: string
): Action<ISetLocationType> => ({
  type: "SET_LOCATION",
  payload: {
    location,
    methods,
  },
});

const setCurrency = (payload: string): Action<string> => ({
  type: "SET_CURRENCY",
  payload,
});

const setLoadingPayment = (payload: boolean): Action<boolean> => ({
  type: "SET_LOADING_PAYMENT",
  payload,
});

const setPaymentStatus = (payload: PaymentStatus): Action<PaymentStatus> => ({
  type: "SET_PAYMENT_STATUS",
  payload,
});

const setSelectedPaymentMethod = (
  payload: PaymentMethod | IDealBank
): Action<PaymentMethod | IDealBank> => ({
  type: "SET_SELECTED_PAYMENT_METHOD",
  payload,
});

const enablePaymentMethod = (payload: string): Action<string> => ({
  type: "ENABLE_PAYMENT_METHOD",
  payload,
});

const disablePaymentMethod = (payload: string): Action<string> => ({
  type: "DISABLE_PAYMENT_METHOD",
  payload,
});

const setDesktopView = (): Action<View> => ({
  type: "SET_VIEW",
  payload: "desktop",
});

const setMobileView = (): Action<View> => ({
  type: "SET_VIEW",
  payload: "mobile",
});

const setBackgroundImage = (
  payload: BackgroundImage | null
): Action<BackgroundImage | null> => ({
  type: "SET_BACKGROUND_IMAGE",
  payload,
});

export {
  selectOneOffPayments,
  selectRecurringPayments,
  setCompanyName,
  setLocation,
  setCurrency,
  setPaymentStatus,
  setSelectedPaymentMethod,
  setLoadingPayment,
  enablePaymentMethod,
  disablePaymentMethod,
  setDesktopView,
  setMobileView,
  setBackgroundImage,
};
