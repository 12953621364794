import { defineMessages } from "react-intl";

const messages = defineMessages({
  oneOffPaymentsTitle: {
    id: "one-off-payments-title",
    defaultMessage: "One-off payments",
  },
  oneOffPaymentsText: {
    id: "one-off-payments-text",
    defaultMessage:
      "Quickly integrate all major payment methods using one of our free plugins, or build your own payment product with our robust, yet simple API.",
  },
  recurringPaymentsTitle: {
    id: "recurring-payments-title",
    defaultMessage: "Recurring payments",
  },
  recurringPaymentsText: {
    id: "recurring-payments-text",
    defaultMessage:
      "Enjoy the freedom to create any payment plan that fits your business, so you can effortlessly collect your recurring payments.",
  },
  paymentTypeNavTitle: {
    id: "payment-type-nav-title",
    defaultMessage: "Choose a payment type",
  },
  paymentStatusNavTitle: {
    id: "payment-status-nav-title",
    defaultMessage: "Choose a payment status",
  },
  personalizeTitle: {
    id: "personalize-title",
    defaultMessage: "Personalize",
  },
  testCardsCopyButtonLabel: {
    id: "test-cards-copy-button-label",
    defaultMessage: "Copy test card number",
  },
  getStartedTitle: {
    id: "resources-get-started-title",
    defaultMessage: "Get started",
  },
  getStartedText: {
    id: "resources-get-started-text",
    defaultMessage:
      "Create a Mollie account to start accepting payments on your website or in your app today!",
  },
  getStartedAction: {
    id: "resources-get-started-action",
    defaultMessage: "Sign up",
  },
  resourcesTitle: {
    id: "resources-title",
    defaultMessage: "Resources",
  },
  resourcesText: {
    id: "resources-text",
    defaultMessage:
      "Easily integrate Mollie into your hosted webshop with one of our free plugins or build it yourself using our provided packages.",
  },
  docsLinkText: {
    id: "resources-docs-link-text",
    defaultMessage: "Read the documentation",
  },
  packagesLinkText: {
    id: "resources-packages-link-text",
    defaultMessage: "View our packages & plugins",
  },
  aboutLinkText: {
    id: "resources-about-link-text",
    defaultMessage: "About Mollie",
  },
  helpLinkText: {
    id: "resources-help-link-text",
    defaultMessage: "Help Center",
  },
});

export default messages;
