import { MessageDescriptor } from "@formatjs/intl";
import { TestCreditCardProvider, BackgroundImage } from "./types";
import qs from "querystringify";

function getCreditCardProviderFromNumber(
  cardNumber: string
): TestCreditCardProvider["id"] | null {
  const [firstDigit, secondDigit] = cardNumber.split("");
  const firstNumber = parseInt(firstDigit);
  const secondNumber = parseInt(secondDigit);

  if (firstNumber === 5 && secondNumber >= 1 && secondNumber <= 5) {
    return "mastercard";
  } else if (firstNumber === 3 && (secondNumber === 4 || secondNumber === 7)) {
    return "amex";
  } else if (firstNumber === 4) {
    return "visa";
  }

  return null;
}

function trimAllWhiteSpace(str: string): string {
  return str.replace(/\s/g, "");
}

function isMessageDescriptor(
  input: MessageDescriptor | string
): input is MessageDescriptor {
  return (input as MessageDescriptor).defaultMessage !== undefined;
}

function parseUnsplashPhotoUrl(url: string): { id: string; ixid: string } {
  // Arrays are objects and expose a `length` property. We can use
  // that to get the last item of the array.
  const { length, [length - 1]: pathname } = url.split("/");

  const [photoId, query] = pathname.split("?");
  const { ixid }: any = qs.parse(query);
  return {
    id: photoId,
    ixid,
  };
}

const unsplashAppName = "mollie_checkout_demo";

function getUnsplashPhotoUrl(backgroundImage: BackgroundImage): string {
  return `https://images.unsplash.com/${backgroundImage.id}?crop=entropy&cs=srgb&fm=jpg&ixid=${backgroundImage.ixid}&ixlib=rb-1.2.1&q=85`;
}

function getUnsplashPhotographerUrl(backgroundImage: BackgroundImage): string {
  return `https://unsplash.com/@${backgroundImage.user.username}?utm_source=${unsplashAppName}&utm_medium=referral`;
}

function getUnsplashPhotographerName(backgroundImage: BackgroundImage): string {
  if (backgroundImage.user.firstName && backgroundImage.user.lastName) {
    return `${backgroundImage.user.firstName} ${backgroundImage.user.lastName}`;
  }

  return backgroundImage.user.username;
}

function getUnsplashUrl(): string {
  return `https://unsplash.com/?utm_source=${unsplashAppName}&utm_medium=referral`;
}

type Environment = "development" | "production" | "staging";

function getEnvironmentByHostname(hostname: string): Environment {
  if (hostname.indexOf("localhost") !== -1) {
    return "development";
  }

  if (hostname.indexOf("demo.dev.mollielabs.io") !== -1) {
    return "staging";
  }

  return "production";
}

export {
  getCreditCardProviderFromNumber,
  trimAllWhiteSpace,
  isMessageDescriptor,
  parseUnsplashPhotoUrl,
  getUnsplashPhotoUrl,
  getUnsplashPhotographerUrl,
  getUnsplashUrl,
  getUnsplashPhotographerName,
  getEnvironmentByHostname,
};
