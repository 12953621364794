import { Helmet } from "react-helmet";
import bgImageDark from "../../../bg-image-dark.jpg";
import bgImageLight from "../../../bg-image-light.jpg";
import styles from "./styles.module.css";

function PreloadAssets() {
  const prefersDarkColorScheme =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href={prefersDarkColorScheme ? bgImageDark : bgImageLight}
          as="image"
        />
      </Helmet>

      <div className={styles["preload-device-image"]} />
    </>
  );
}

export default PreloadAssets;
