import { intlData } from "../../../i18n";
import { FormattedMessage } from "react-intl";
import { HStack } from "../../Stack";
import Select from "../../Select";
import { SmallText } from "../../Text";
import { useNavigate } from "react-router-dom";
import useAppLanguage from "../../../hooks/useAppLanguage";

function LocaleSelect() {
  const navigate = useNavigate();
  const appLanguage = useAppLanguage();

  return (
    <HStack alignment="space-between">
      <SmallText colorScheme="light">
        <FormattedMessage
          id="sidebar-home-locale-select-title"
          defaultMessage="Language"
        />
      </SmallText>

      <Select
        value={appLanguage}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          navigate(`/${e.target.value}`);
        }}
        data-testid="select"
        colorScheme="light"
      >
        {intlData.map((option) => (
          <option value={option.appLanguage} key={option.appLanguage}>
            {option.label}
          </option>
        ))}
      </Select>
    </HStack>
  );
}

export default LocaleSelect;
