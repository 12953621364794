import { Link, useLocation } from "react-router-dom";
import { H3 } from "../../Heading";
import { FormattedMessage } from "react-intl";
import { SmallText } from "../../Text";
import { HStack, VStack } from "../../Stack";
import useQueryString from "../../../hooks/useQueryString";
import { ReactComponent as East } from "../../../svg/east.svg";
import { ReactComponent as OneOffPayments } from "../../../svg/one-off-payments.svg";
import { ReactComponent as RecurringPayments } from "../../../svg/recurring-payments.svg";
import styles from "./styles.module.css";
import {
  useDispatch,
  useStore,
  selectOneOffPayments,
  selectRecurringPayments,
} from "../../Store";
import clsx from "clsx";
import messages from "../messages";
import FadeIn from "../../FadeIn";

function NavButtons() {
  const { paymentType } = useStore();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [queryParams] = useQueryString();

  return (
    <VStack as="nav" className={styles.nav}>
      <FadeIn scale delay={120}>
        <Link
          className={clsx(
            styles.button,
            paymentType === "one-off-payments" && styles.active
          )}
          to={`one-off-payments/${search}`}
          onMouseEnter={() =>
            dispatch(selectOneOffPayments(queryParams.methods))
          }
        >
          <OneOffPayments />

          <VStack className={styles.details}>
            <HStack alignment="space-between">
              <H3>
                <FormattedMessage {...messages.oneOffPaymentsTitle} />
              </H3>
              <East aria-hidden="true" />
            </HStack>

            <div>
              <SmallText className={styles.description}>
                <FormattedMessage {...messages.oneOffPaymentsText} />
              </SmallText>
            </div>
          </VStack>
        </Link>
      </FadeIn>

      <FadeIn scale delay={160}>
        <Link
          className={clsx(
            styles.button,
            paymentType === "recurring-payments" && styles.active
          )}
          to={`recurring-payments/${search}`}
          onMouseEnter={() =>
            dispatch(selectRecurringPayments(queryParams.methods))
          }
        >
          <RecurringPayments />

          <VStack className={styles.details}>
            <HStack alignment="space-between">
              <H3>
                <FormattedMessage {...messages.recurringPaymentsTitle} />
              </H3>
              <East aria-hidden="true" />
            </HStack>

            <div>
              <SmallText className={styles.description}>
                <FormattedMessage {...messages.recurringPaymentsText} />
              </SmallText>
            </div>
          </VStack>
        </Link>
      </FadeIn>
    </VStack>
  );
}

export default NavButtons;
