// Auto generated file. Do no change. Go to Crowdin to update the translations and run './node_modules/.bin/mollie-crowdin download' to update this file.
export default {
  "background-image-picker-input-placeholder": "Rechercher Unsplash",
  "cc-form-card-expiry-date-label": "Date d'expiration",
  "cc-form-card-number-label": "Numéro de carte",
  "cc-form-card-submit-button-label": "Payer",
  "cc-form-error-future-date": "Pas une date future",
  "cc-form-error-invalid-date": "Date non valide",
  "cc-form-error-max-length": "{maxLength} caractères ou moins",
  "cc-form-error-min-length": "{minLength} caractères ou plus",
  "cc-form-error-required-field": "Ce champ est obligatoire",
  "cc-form-name-on-card-label": "Nom sur la carte",
  "checkout-header-description-one-off-payments": "Votre paiement",
  "checkout-header-description-recurring-payments": "Votre abonnement",
  close: "Fermez",
  continue: "Continuer",
  "currency-aud": "Dollar australien",
  "currency-cad": "Dollar canadien",
  "currency-chf": "Frank suisse",
  "currency-czk": "Couronne tchèque",
  "currency-dkk": "Couronne danoise",
  "currency-eur": "Euro",
  "currency-gbp": "Livre Sterling",
  "currency-hkd": "Dollar de Hong Kong",
  "currency-huf": "Forint hongrois",
  "currency-ils": "Nouveau Shekel israélien",
  "currency-jpy": "Yen japonais",
  "currency-nok": "Couronne norvégienne",
  "currency-nzd": "Dollar néo-zélandais",
  "currency-php": "Piso philippin",
  "currency-pln": "Złoty polonais",
  "currency-rub": "Rouble russe",
  "currency-sek": "Couronne suédoise",
  "currency-sgd": "Dollar de Singapour",
  "currency-usd": "Dollar américain",
  "dummy-checkout-disclaimer": "Démo sécurisée et effectué par",
  "dummy-checkout-payment-canceled": "Paiement annulé",
  "dummy-checkout-payment-expired": "Paiement expiré",
  "dummy-checkout-payment-failed": "Paiement échoué",
  "dummy-checkout-payment-successful": "Paiement réussi",
  "dummy-checkout-photo-credits":
    "Image de fond par {photographerLink} sur {unsplashLink}",
  "dummy-checkout-status-finalize": "Finaliser",
  "dummy-checkout-status-select-label": "Choisissez un statut de paiement",
  "dummy-checkout-subscription-canceled": "Abonnement échoué",
  "dummy-checkout-subscription-expired": "Abonnement échoué",
  "dummy-checkout-subscription-failed": "L'abonnement a échoué",
  "dummy-checkout-subscription-successful": "Abonnement confirmé",
  "location-austria": "Autriche",
  "location-belgium": "Belgique",
  "location-denmark": "Danemark",
  "location-finland": "Finlande",
  "location-france": "France",
  "location-germany": "Allemagne",
  "location-greece": "Grèce",
  "location-italy": "Italie",
  "location-netherlands": "Pays-Bas",
  "location-norway": "Norvège",
  "location-poland": "Pologne",
  "location-spain": "Espagne",
  "location-sweden": "Suède",
  "location-switzerland": "Suisse",
  "name-placeholder": "Votre entreprise",
  "no-results-found": "Aucun résultat trouvé",
  "not-found-button": "Aller à la page d'accueil",
  "not-found-text":
    "La page que vous recherchiez n'existe pas. Il est possible qu'il ait été supprimé ou déplacé.",
  "not-found-title": "Page non trouvée",
  "one-off-payments-text":
    "Intégrez rapidement et tout simplement les principales méthodes de paiement en utilisant nos plugins gratuits. Vous pouvez aussi créer votre propre produit de paiement grâce à notre API.",
  "one-off-payments-title": "Paiements ponctuels",
  "one-remaining-payment-method-tooltip-text":
    "Au moins un mode de paiement doit rester activé.",
  "payment-method-bank-transfer": "Virement",
  "payment-method-credit-card": "Carte",
  "payment-method-gift-cards": "Carte cadeaux",
  "payment-method-kbc-cbc-button": "Bouton de paiement KBC / CBC",
  "payment-method-klarna-pay-later": "Klarna: Pay Later",
  "payment-method-klarna-slice-it": "Klarna: Slice It",
  "payment-method-sofort": "SOFORT Banking",
  "payment-method-vouchers": "Cheques",
  "payment-methods-title": "Modes de paiement",
  "payment-status-canceled": "Annulé",
  "payment-status-expired": "Expiré",
  "payment-status-failed": "Echoué",
  "payment-status-nav-title": "Choisissez un statut de paiement",
  "payment-status-paid": "Payé",
  "payment-status-processing": "Traitement",
  "payment-type-nav-title": "Choisir un type de paiement",
  "personalize-background-image-clear-button-label":
    "Réinitialiser l'image de fond",
  "personalize-background-image-title": "Image de fond",
  "personalize-background-image-value": "Unsplash photo",
  "personalize-currency-title": "Devise",
  "personalize-location-title": "Emplacement",
  "personalize-name-title": "Nom de l'entreprise",
  "personalize-title": "Personnaliser",
  "recurring-payments-text":
    "Prenez la liberté d’offrir toute la souplesse de paiement lié à votre modèle économiques (ou business model), pour que vous puissiez collecter sans effort vos paiements récurrents.",
  "recurring-payments-title": "Paiements récurrents",
  "resources-about-link-text": "À propos de Mollie",
  "resources-docs-link-text": "Lire la documentation",
  "resources-get-started-action": "S'inscrire",
  "resources-get-started-text":
    "Créez un compte Mollie pour commencer à accepter les paiements sur votre site Web ou dans votre application dès aujourd'hui !",
  "resources-get-started-title": "Commencer",
  "resources-help-link-text": "Service d’assistance",
  "resources-packages-link-text": "Voir nos forfaits & plugins",
  "resources-text":
    "Intégrez facilement Mollie dans votre boutique en ligne hébergée avec l'un de nos plugins gratuits ou construisez-le vous-même en utilisant nos paquets fournis.",
  "resources-title": "Ressources",
  "sidebar-home-locale-select-title": "Langue",
  "sidebar-home-text":
    "Checkout transforme un formulaire de paiement standard simple en une expérience professionnelle sur la marque qui inspire la confiance et stimule les conversions. Tout cela sans avoir à écrire une seule ligne de code.",
  "start-over": "Recommençons",
  "test-cards-authentication-title": "Authentification",
  "test-cards-copy-button-label": "Copier le numéro de carte de test",
  "test-cards-decline-title": "Refuser",
  "test-cards-numbers-title": "Cartes de test",
  "test-cards-provider-label": "Fournisseur",
  "test-cards-success-title": "Réussite",
  title: "Découvrir Mollie Checkout",
};
