import React from "react";
import clsx from "clsx";
import styles from "./styles.module.css";
import { ColorScheme } from "../../types";

export interface TextProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  as?: React.ElementType | string;
  colorScheme?: ColorScheme;
}

export function SmallText({
  as: Component = "span",
  className,
  colorScheme,
  ...props
}: TextProps) {
  return (
    <Component
      className={clsx(
        className,
        styles["small-text"],
        colorScheme && styles[`${colorScheme}-scheme`]
      )}
      {...props}
    />
  );
}

export function Text({
  as: Component = "span",
  className,
  colorScheme,
  ...props
}: TextProps) {
  return (
    <Component
      className={clsx(
        className,
        styles.text,
        colorScheme && styles[`${colorScheme}-scheme`]
      )}
      {...props}
    />
  );
}
