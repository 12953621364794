import unionBy from "lodash/unionBy";
import { defaultPaymentMethods, paymentMethods } from "../../constants";
import { CountryCode, PaymentMethod, PaymentType } from "../../types";

const bySupportedPaymentType =
  (paymentType: PaymentType) =>
  (method: PaymentMethod): boolean =>
    paymentType === "one-off-payments"
      ? method.supports.oneOffPayments
      : method.supports.recurringPayments;

function unionById(prioritizedMethods: PaymentMethod[]) {
  return unionBy(prioritizedMethods, defaultPaymentMethods, "id");
}

/**
 * Returns a prioritized list of payment methods, given a country
 * code. Ordering is based on `/application/classesPayment/Types.php`
 * in Mollie Platform.
 */
function getPaymentMethodsByCountryCode(
  countryCode?: CountryCode
): PaymentMethod[] {
  switch (countryCode) {
    case "NL":
      return unionById([
        paymentMethods.iDeal,
        paymentMethods.creditCard,
        paymentMethods.klarnaPayLater,
        paymentMethods.klarnaSliceIt,
        paymentMethods.payPal,
        paymentMethods.bankTransfer,
        paymentMethods.sofort,
        paymentMethods.giftCards,
      ]);

    case "BE":
      return unionById([
        paymentMethods.bancontact,
        paymentMethods.creditCard,
        paymentMethods.klarnaPayLater,
        paymentMethods.kbcCbc,
        paymentMethods.belfius,
        paymentMethods.payPal,
        paymentMethods.bankTransfer,
        paymentMethods.vouchers,
      ]);

    case "DE":
      return unionById([
        paymentMethods.sofort,
        paymentMethods.giropay,
        paymentMethods.creditCard,
        paymentMethods.klarnaPayLater,
        paymentMethods.klarnaSliceIt,
        paymentMethods.payPal,
        paymentMethods.bankTransfer,
      ]);

    case "AT":
      return unionById([
        paymentMethods.eps,
        paymentMethods.sofort,
        paymentMethods.creditCard,
        paymentMethods.klarnaPayLater,
        paymentMethods.klarnaSliceIt,
        paymentMethods.payPal,
        paymentMethods.bankTransfer,
      ]);

    case "CH":
      return unionById([
        paymentMethods.creditCard,
        paymentMethods.sofort,
        paymentMethods.payPal,
      ]);

    case "FR":
      return unionById([
        paymentMethods.creditCard,
        paymentMethods.payPal,
        paymentMethods.bankTransfer,
        paymentMethods.vouchers,
      ]);

    case "ES":
      return unionById([
        paymentMethods.creditCard,
        paymentMethods.payPal,
        paymentMethods.sofort,
        paymentMethods.bankTransfer,
      ]);

    case "DK":
    case "NO":
    case "FI":
    case "SE":
      return unionById([
        paymentMethods.klarnaPayLater,
        paymentMethods.klarnaSliceIt,
      ]);

    case "IT":
      return unionById([
        paymentMethods.creditCard,
        paymentMethods.sofort,
        paymentMethods.payPal,
        paymentMethods.bankTransfer,
      ]);

    case "GR":
      return unionById([paymentMethods.creditCard]);

    case "PL":
      return unionById([
        paymentMethods.przelewy24,
        paymentMethods.creditCard,
        paymentMethods.sofort,
      ]);

    case "UK":
      return unionById([
        paymentMethods.creditCard,
        paymentMethods.applePay,
        paymentMethods.payPal,
        paymentMethods.klarnaPayLater,
        paymentMethods.klarnaSliceIt,
        paymentMethods.iDeal,
        paymentMethods.bancontact,
        paymentMethods.giropay,
        paymentMethods.sofort,
        paymentMethods.kbcCbc,
        paymentMethods.belfius,
        paymentMethods.paysafecard,
        paymentMethods.bankTransfer,
        paymentMethods.przelewy24,
        paymentMethods.giftCards,
        paymentMethods.vouchers,
      ]);

    default:
      return defaultPaymentMethods;
  }
}

interface Options {
  paymentType: PaymentType;
  methodsQueryParam?: string;
  countryCode?: CountryCode;
}

function getPaymentMethods({
  paymentType,
  methodsQueryParam,
  countryCode,
}: Options): PaymentMethod[] {
  const paymentMethods: PaymentMethod[] =
    getPaymentMethodsByCountryCode(countryCode);
  const paymentMethodsBySupportedPaymentType: PaymentMethod[] =
    paymentMethods.filter(bySupportedPaymentType(paymentType));

  // If no query param set, return default set.
  if (!methodsQueryParam) {
    return paymentMethodsBySupportedPaymentType;
  }

  // Parse query param value into chunks of 2 characters.
  const paymentMethodIdsFromQueryParam = methodsQueryParam.match(/.{1,2}/g);

  // Get initial methods based on ID’s in the URL.
  const initialPaymentMethods = paymentMethodsBySupportedPaymentType.map(
    (method) => {
      return {
        ...method,
        enabled: Boolean(paymentMethodIdsFromQueryParam?.includes(method.id)),
      };
    }
  );

  // Check if resulting array would contain at least 1 enabled method.
  if (initialPaymentMethods.every((method) => method.enabled === false)) {
    return paymentMethodsBySupportedPaymentType;
  }

  return initialPaymentMethods;
}

export { getPaymentMethods, getPaymentMethodsByCountryCode };
