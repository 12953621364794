import React from "react";
import reducer from "./reducer";
import { StoreProviderProps, State, Dispatch, BackgroundImage } from "./types";
import useQueryString from "../../hooks/useQueryString";
import { getPaymentMethods } from "./utils";
import { PaymentType, CountryCode } from "../../types";
import { useLocation } from "react-router";
import { countries, currencies, paymentStatuses } from "../../constants";

const StoreContext = React.createContext<Partial<State>>({});
const DispatchContext = React.createContext<Dispatch>(() => {});

function getInitialBackgroundImage(
  queryParams: Record<string, any>
): BackgroundImage | null {
  if (!queryParams.bgid || !queryParams.bgixid || !queryParams.bgusername) {
    return null;
  }

  return {
    id: queryParams.bgid,
    ixid: queryParams.bgixid,
    user: {
      firstName: queryParams.bgfname || null,
      lastName: queryParams.bglname || null,
      username: queryParams.bgusername,
    },
  };
}

function StoreProvider(props: StoreProviderProps) {
  const [queryParams] = useQueryString();
  const { pathname } = useLocation();

  const initialPaymentType: PaymentType = pathname.includes("one-off-payments")
    ? "one-off-payments"
    : "recurring-payments";
  const initialLocation = (queryParams.loc as CountryCode) || countries[0].code;
  const initialState: State = {
    paymentType: initialPaymentType,
    name: queryParams.name || "",
    location: initialLocation,
    currency: queryParams.currency || currencies[0].code,
    paymentMethods: getPaymentMethods({
      paymentType: initialPaymentType,
      methodsQueryParam: queryParams.methods,
      countryCode: initialLocation,
    }),
    backgroundImage: getInitialBackgroundImage(queryParams),
    paymentStatus: paymentStatuses.processing,
    selectedPaymentMethod: null,
    loadingPayment: false,
    view: "desktop",
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {props.children}
      </DispatchContext.Provider>
    </StoreContext.Provider>
  );
}

function useStore() {
  const store = React.useContext(StoreContext);
  if (typeof store === "undefined") {
    throw new Error("useStore() must be used within a StoreProvider");
  }
  return store;
}

function useDispatch() {
  const dispatch = React.useContext(DispatchContext);
  if (typeof dispatch === "undefined") {
    throw new Error("useDispatch() must be used within a StoreProvider");
  }
  return dispatch;
}

export * from "./actions";
export * from "./types";
export { StoreProvider, useStore, useDispatch };
