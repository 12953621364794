import React from "react";
import clsx from "clsx";
import inputStyles from "../Input/styles.module.css";
import styles from "./styles.module.css";
import { ColorScheme } from "../../types";

interface Props extends React.HTMLProps<HTMLSelectElement> {
  children?: React.ReactNode;
  className?: string;
  variant?: "primary" | "secondary";
  colorScheme?: ColorScheme;
}

function Select({
  className,
  variant = "secondary",
  colorScheme,
  ...props
}: Props) {
  return (
    <select
      className={clsx(
        inputStyles.input,
        colorScheme && inputStyles[`${colorScheme}-scheme`],
        colorScheme && styles[`${colorScheme}-scheme`],
        styles.select,
        styles[variant],
        className
      )}
      {...props}
    />
  );
}

export default Select;
