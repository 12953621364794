// Auto generated file. Do no change. Go to Crowdin to update the translations and run './node_modules/.bin/mollie-crowdin download' to update this file.
export default {
  "background-image-picker-input-placeholder": "Zoek Unsplash",
  "cc-form-card-expiry-date-label": "Vervaldatum",
  "cc-form-card-number-label": "Kaartnummer",
  "cc-form-card-submit-button-label": "Betalen",
  "cc-form-error-future-date": "Geen toekomstige datum",
  "cc-form-error-invalid-date": "Ongeldige datum",
  "cc-form-error-max-length": "{maxLength} tekens of minder",
  "cc-form-error-min-length": "{minLength} tekens of meer",
  "cc-form-error-required-field": "Dit veld is verplicht",
  "cc-form-name-on-card-label": "Naam op kaart",
  "checkout-header-description-one-off-payments": "Jouw betaling",
  "checkout-header-description-recurring-payments": "Jouw abonnement",
  close: "Sluiten",
  continue: "Doorgaan",
  "currency-aud": "Australische Dollar",
  "currency-cad": "Canadese Dollar",
  "currency-chf": "Zwitserse Frank",
  "currency-czk": "Tsjechische Koruna",
  "currency-dkk": "Deense kroon",
  "currency-eur": "Euro",
  "currency-gbp": "Brits pond",
  "currency-hkd": "Hongkong-dollar",
  "currency-huf": "Hongaarse Forint",
  "currency-ils": "Nieuwe Israëlische sjekel",
  "currency-jpy": "Japanse Yen",
  "currency-nok": "Noorse kroon",
  "currency-nzd": "Nieuw-Zeelands dollar",
  "currency-php": "Filippijnse Piso",
  "currency-pln": "Poolse Złoty",
  "currency-rub": "Russische roebel",
  "currency-sek": "Zweedse kroon",
  "currency-sgd": "Singapore-dollar",
  "currency-usd": "Amerikaanse Dollar",
  "dummy-checkout-disclaimer": "Demo beveiligd en geleverd door",
  "dummy-checkout-payment-canceled": "Betaling geannuleerd",
  "dummy-checkout-payment-expired": "Betaling verlopen",
  "dummy-checkout-payment-failed": "Betaling mislukt",
  "dummy-checkout-payment-successful": "Betaling geslaagd",
  "dummy-checkout-photo-credits":
    "Achtergrondafbeelding door {photographerLink} op {unsplashLink}",
  "dummy-checkout-status-finalize": "Voltooi",
  "dummy-checkout-status-select-label": "Selecteer een betalingsstatus",
  "dummy-checkout-subscription-canceled": "Abonnement mislukt",
  "dummy-checkout-subscription-expired": "Abonnement mislukt",
  "dummy-checkout-subscription-failed": "Abonnement mislukt",
  "dummy-checkout-subscription-successful": "Abonnement bevestigt",
  "location-austria": "Oostenrijk",
  "location-belgium": "België",
  "location-denmark": "Denemarken",
  "location-finland": "Finland",
  "location-france": "Frankrijk",
  "location-germany": "Duitsland",
  "location-greece": "Griekenland",
  "location-italy": "Italië",
  "location-netherlands": "Nederland",
  "location-norway": "Noorwegen",
  "location-poland": "Polen",
  "location-spain": "Spanje",
  "location-sweden": "Zweden",
  "location-switzerland": "Zwitserland",
  "name-placeholder": "Jouw bedrijf",
  "no-results-found": "Geen resultaten gevonden",
  "not-found-button": "Ga naar de startpagina",
  "not-found-text":
    "De pagina die je zocht, bestaat niet. Het kan zijn verwijderd of verplaatst.",
  "not-found-title": "Pagina niet gevonden",
  "one-off-payments-text":
    "Met onze gratis plug-ins integreer je alle bekende betaalmethodes in jouw platform zonder technische kennis. Of bouw je eigen betaalomgeving met onze solide maar toegankelijke API.",
  "one-off-payments-title": "Eenmalige betalingen",
  "one-remaining-payment-method-tooltip-text":
    "Ten minste één betaalmethode moet ingeschakeld blijven.",
  "payment-method-bank-transfer": "Overboeking",
  "payment-method-credit-card": "Kaart",
  "payment-method-gift-cards": "Cadeaukaarten",
  "payment-method-kbc-cbc-button": "KBC/CBC-Betaalknop",
  "payment-method-klarna-pay-later": "Klarna: Achteraf Betalen",
  "payment-method-klarna-slice-it": "Klarna: Gespreid Betalen",
  "payment-method-sofort": "SOFORT Banking",
  "payment-method-vouchers": "Cheques",
  "payment-methods-title": "Betaalmethodes",
  "payment-status-canceled": "Geannuleerd",
  "payment-status-expired": "Verlopen",
  "payment-status-failed": "Mislukt",
  "payment-status-nav-title": "Kies een betalingsstatus",
  "payment-status-paid": "Betaald",
  "payment-status-processing": "Wordt verwerkt",
  "payment-type-nav-title": "Kies een betalingstype",
  "personalize-background-image-clear-button-label":
    "Achtergrondafbeelding resetten",
  "personalize-background-image-title": "Achtergrond",
  "personalize-background-image-value": "Unsplash foto",
  "personalize-currency-title": "Valuta",
  "personalize-location-title": "Locatie",
  "personalize-name-title": "Bedrijfsnaam",
  "personalize-title": "Personaliseren",
  "recurring-payments-text":
    "Geniet van de vrijheid om elk betaalplan op te stellen dat bij uw bedrijf past, zodat u moeiteloos uw terugkerende betalingen kunt innen.",
  "recurring-payments-title": "Terugkerende betalingen",
  "resources-about-link-text": "Over Mollie",
  "resources-docs-link-text": "Lees de documentatie",
  "resources-get-started-action": "Aanmelden",
  "resources-get-started-text":
    "Maak vandaag een Mollie account aan om betalingen te accepteren op je website of in je app!",
  "resources-get-started-title": "Aan de slag",
  "resources-help-link-text": "Helpcenter",
  "resources-packages-link-text": "Bekijk onze pakketten & plugins",
  "resources-text":
    "Integreer Mollie eenvoudig in uw gehoste webshop met een van onze gratis plugins of bouw het zelf met behulp van onze meegeleverde pakketten.",
  "resources-title": "Resources",
  "sidebar-home-locale-select-title": "Taal",
  "sidebar-home-text":
    "Checkout maakt van een eenvoudig, standaard betaalformulier een professionele, on-brand ervaring die conversie stimuleert. Allemaal zonder één regel code te hoeven schrijven.",
  "start-over": "Begin opnieuw",
  "test-cards-authentication-title": "Authenticatie",
  "test-cards-copy-button-label": "Nummer van de testkaart kopiëren",
  "test-cards-decline-title": "Afwijzen",
  "test-cards-numbers-title": "Test kaarten",
  "test-cards-provider-label": "Aanbieder",
  "test-cards-success-title": "Succesvol",
  title: "Ontdek Mollie Checkout",
};
