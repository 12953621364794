import messagesDe from "./intl/de";
import messagesEnGb from "./intl/en-GB";
import messagesEnUs from "./intl/en-US";
import messagesFr from "./intl/fr";
import messagesFrBe from "./intl/fr-BE";
import messagesNl from "./intl/nl";
import messagesNlBe from "./intl/nl-BE";

export const appLanguages = [
  "en",
  "uk",
  "de",
  "fr",
  "be-fr",
  "nl",
  "be",
] as const;
export type AppLanguage = typeof appLanguages[number];
export type Locale =
  | "en"
  | "en-GB"
  | "de"
  | "fr-FR"
  | "fr-BE"
  | "nl-NL"
  | "nl-BE";

export function getLocaleFromAppLanguage(language: AppLanguage): Locale {
  switch (language) {
    case "uk":
      return "en-GB";
    case "de":
      return "de";
    case "fr":
      return "fr-FR";
    case "be-fr":
      return "fr-BE";
    case "nl":
      return "nl-NL";
    case "be":
      return "nl-BE";
    default:
      return "en";
  }
}

export function getAppLanguageFromLocale(locale: Locale): AppLanguage {
  switch (locale) {
    case "en-GB":
      return "uk";
    case "de":
      return "de";
    case "fr-FR":
      return "fr";
    case "fr-BE":
      return "be-fr";
    case "nl-NL":
      return "nl";
    case "nl-BE":
      return "be";
    default:
      return "en";
  }
}

interface Meta {
  title: string;
  description: string;
}

interface LocaleData {
  locale: Locale;
  appLanguage: AppLanguage;
  label: string;
  messages: Record<string, string>;
}

const meta: Record<"en" | "de" | "fr" | "nl", Meta> = {
  en: {
    title: "Checkout made simple for your online store | Mollie",
    description:
      "Turn a standard payment form into a professional, on-brand experience: ✓ inspire trust ✓ drive conversions ✓ no code implementation. Try it out live »",
  },
  de: {
    title: "Checkout für Ihren Online-Shop leicht gemacht | Mollie",
    description:
      "So wird ein Standard-Zahlungsformular zu einem authentischen Erlebnis: ✓ Vertrauen erwecken ✓ Conversions steigern ✓ Codefrei. Jetzt live ausprobieren »",
  },
  fr: {
    title: "[Demo] Checkout simple à implémenter sur votre site | Mollie",
    description:
      "Tournez un formulaire de paiement basique en une expérience authentique : ✓ inspirez confiance ✓ dopez vos conversions ✓ mise en place facile. Essayez de suite »",
  },
  nl: {
    title: "Checkout voor uw online winkel gemakkelijk gemaakt | Mollie",
    description:
      "Maak van een standaard betalingsformulier een authentieke ervaring: ✓ Inspireer vertrouwen ✓ Verhoog conversies ✓ Code-vrij. Probeer het nu live »",
  },
};

export function getAppMeta(language: AppLanguage): Meta {
  switch (language) {
    case "de":
      return meta.de;
    case "fr":
    case "be-fr":
      return meta.fr;
    case "nl":
    case "be":
      return meta.nl;
    default:
      return meta.en;
  }
}

export const intlData: LocaleData[] = [
  {
    locale: "en",
    appLanguage: "en",
    label: "English",
    messages: messagesEnUs,
  },
  {
    locale: "en-GB",
    appLanguage: "uk",
    label: "English GB",
    messages: messagesEnGb,
  },
  {
    locale: "de",
    appLanguage: "de",
    label: "Deutsch",
    messages: messagesDe,
  },
  {
    locale: "fr-FR",
    appLanguage: "fr",
    label: "Français",
    messages: messagesFr,
  },
  {
    locale: "fr-BE",
    appLanguage: "be-fr",
    label: "Français BE",
    messages: messagesFrBe,
  },
  {
    locale: "nl-NL",
    appLanguage: "nl",
    label: "Nederlands",
    messages: messagesNl,
  },
  {
    locale: "nl-BE",
    appLanguage: "be",
    label: "Nederlands BE",
    messages: messagesNlBe,
  },
];

interface IntlProviderProps {
  locale: Locale;
  messages: LocaleData["messages"];
}

export function getIntlProviderProps(locale: Locale): IntlProviderProps {
  const byLocale = (option: LocaleData): boolean => option.locale === locale;
  const filteredOptions = intlData.filter(byLocale);
  let messages: LocaleData["messages"] = messagesEnUs;
  if (filteredOptions.length > 0) {
    locale = filteredOptions[0].locale;
    messages = filteredOptions[0].messages;
  }
  return { locale, messages };
}

const browserLanguage = navigator.language.split(/[-_]/)[0];

export function defaultAppLanguage(): AppLanguage {
  if (appLanguages.includes(browserLanguage as AppLanguage)) {
    return browserLanguage as AppLanguage;
  }

  return "en";
}

export function getAppLangaugeFromPathname(pathname: string): AppLanguage {
  const parsedAppLanguage = pathname.split("/")[1];
  if (appLanguages.includes(parsedAppLanguage as AppLanguage)) {
    return pathname.split("/")[1] as AppLanguage;
  }
  return "en";
}
