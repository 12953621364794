import { TestCreditCardSet } from "../types";

export const testCreditCardSets: TestCreditCardSet[] = [
  {
    provider: {
      id: "visa",
      name: "VISA",
    },
    success: "4242 4242 4242 4242",
    authentication: "4543 4740 0224 9996",
    decline: "4543 4740 0224 0002",
  },
  {
    provider: {
      id: "mastercard",
      name: "Mastercard",
    },
    success: "5436 0310 3060 6378",
    authentication: "2223 0000 1047 9399",
    decline: "5436 0310 3060 0004",
  },
  {
    provider: { id: "amex", name: "American Express" },
    success: "3456 789012 34564",
    authentication: "3782 822463 10005",
    decline: "3782 822463 00006",
  },
];
