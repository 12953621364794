import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

/**
 * Custom hook to persist component state in query params. Fork of
 * `useQueryString()` from `use-route-as-state`, modified for React
 * Router v6.
 *
 * @see https://github.com/baruchiro/use-route-as-state
 */

const getQueryParamsAsObject = (search: string) => {
  const params: Record<string, string> = {};
  new URLSearchParams(search).forEach((value, key) => (params[key] = value));
  return params;
};

const removeUndefined = <T extends Record<string, string | undefined>>(
  obj: T
) =>
  Object.keys(obj)
    .filter((key) => obj[key] !== undefined)
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: obj[key] as string,
      }),
      {} as Record<string, string>
    );

const objectToQueryParams = (obj: Record<string, string>) =>
  "?" +
  Object.keys(obj)
    .filter((key) => obj[key] !== undefined)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");

const encodeValues = (obj: Record<string, string>) =>
  Object.keys(removeUndefined(obj)).reduce(
    (acc, key) => ({
      ...acc,
      [key]: obj[key] && encodeURIComponent(obj[key]),
    }),
    {} as Record<string, string>
  );

const useDecodedLocation = () => {
  const { search, ...rest } = useLocation();
  const decodedSearch = useMemo(() => getQueryParamsAsObject(search), [search]);
  return { search: decodedSearch, ...rest };
};

type DisaptchState = Dispatch<SetStateAction<Record<string, string>>>;

const useQueryString = (
  defaultValues?: Record<string, string>
): [Record<string, string>, DisaptchState] => {
  const { pathname, search } = useDecodedLocation();
  const navigate = useNavigate();

  const updateQuery: DisaptchState = useCallback(
    (dispatch: SetStateAction<Record<string, string>>) => {
      const updatedParams =
        typeof dispatch === "function" ? dispatch(search) : dispatch;
      navigate(pathname + objectToQueryParams(encodeValues(updatedParams)), {
        replace: true,
      });
    },
    [search, navigate, pathname]
  );

  const queryWithDefault = useMemo(
    () => Object.assign({}, defaultValues, removeUndefined(search)),
    [search, defaultValues]
  );

  return [queryWithDefault, updateQuery];
};

export default useQueryString;
