import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import styles from "./styles.module.css";
import Home from "./Home";
import { paymentTypes } from "../../constants";
import useAppLanguage from "../../hooks/useAppLanguage";

const Configure = React.lazy(() => import("./Configure"));
const Preview = React.lazy(() => import("./Preview"));
const SelectStatus = React.lazy(() => import("./SelectStatus"));
const Resources = React.lazy(() => import("./Resources"));

function Sidebar() {
  const appLanguage = useAppLanguage();

  return (
    <aside className={styles.sidebar} role="navigation">
      <React.Suspense fallback={null}>
        <Routes>
          <Route path="/" element={<Home />} />
          {paymentTypes.map((type) => (
            <React.Fragment key={type}>
              <Route path={`/${type}`} element={<Configure />} />
              <Route path={`/${type}/ideal`} element={<Configure />} />
              <Route path={`/${type}/credit-card`} element={<Configure />} />
              <Route
                path={`/${type}/payment/processing`}
                element={<SelectStatus />}
              />
              <Route
                path={`/${type}/ideal/payment/processing`}
                element={<SelectStatus />}
              />
              <Route
                path={`/${type}/credit-card/payment/processing`}
                element={<SelectStatus />}
              />
              <Route
                path={`/${type}/payment/:paymentStatus`}
                element={<Resources />}
              />
              <Route
                path={`/${type}/ideal/payment/:paymentStatus`}
                element={<Resources />}
              />
              <Route
                path={`/${type}/credit-card/payment/:paymentStatus`}
                element={<Resources />}
              />
              <Route
                path={`/${type}/credit-card/payment/processing/:paymentOutcome`}
                element={<Configure />}
              />
              <Route
                path={`/${type}/credit-card/payment/:paymentStatus/:paymentOutcome`}
                element={<Resources />}
              />
              <Route
                path={`/${type}/preview/payment/:paymentStatus/resources`}
                element={<Resources />}
              />
              <Route
                path={`/${type}/preview/ideal/payment/:paymentStatus/resources`}
                element={<Resources />}
              />
              <Route
                path={`/${type}/preview/credit-card/payment/:paymentStatus/resources`}
                element={<Resources />}
              />
              <Route path={`/${type}/preview/*`} element={<Preview />} />
            </React.Fragment>
          ))}
          <Route
            path="*"
            element={<Navigate to={`/${appLanguage}/not-found`} replace />}
          />
        </Routes>
      </React.Suspense>
    </aside>
  );
}

export default Sidebar;
