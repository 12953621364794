import { IDealBank } from "../types";
import abnAmroIcon from "../svg/abn-amro.svg";
import ingIcon from "../svg/ing.svg";
import rabobankIcon from "../svg/rabobank.svg";
import asnBankIcon from "../svg/asn-bank.svg";
import bunqIcon from "../svg/bunq.svg";
import knabIcon from "../svg/knab.svg";
import regiobankIcon from "../svg/regiobank.svg";
import revolutIcon from "../svg/revolut.svg";
import snsBankIcon from "../svg/sns-bank.svg";
import triodosIcon from "../svg/triodos.svg";
import vanLanschotIcon from "../svg/van-lanschot.svg";

export const iDealBanks: IDealBank[] = [
  {
    id: "AB",
    name: "ABN AMRO",
    icon: abnAmroIcon,
  },
  {
    id: "IN",
    name: "ING",
    icon: ingIcon,
  },
  {
    id: "RA",
    name: "Rabobank",
    icon: rabobankIcon,
  },
  {
    id: "AS",
    name: "ASN Bank",
    icon: asnBankIcon,
  },
  {
    id: "BU",
    name: "bunq",
    icon: bunqIcon,
  },
  {
    id: "KN",
    name: "Knab",
    icon: knabIcon,
  },
  {
    id: "RE",
    name: "Regiobank",
    icon: regiobankIcon,
  },
  {
    id: "RV",
    name: "Revolut",
    icon: revolutIcon,
  },
  {
    id: "SN",
    name: "SNS Bank",
    icon: snsBankIcon,
  },
  {
    id: "TR",
    name: "Triodos",
    icon: triodosIcon,
  },
  {
    id: "VL",
    name: "Van Lanschot",
    icon: vanLanschotIcon,
  },
];
