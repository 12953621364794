// Auto generated file. Do no change. Go to Crowdin to update the translations and run './node_modules/.bin/mollie-crowdin download' to update this file.
export default {
  "background-image-picker-input-placeholder": "Search Unsplash",
  "cc-form-card-expiry-date-label": "Expiry date",
  "cc-form-card-number-label": "Card number",
  "cc-form-card-submit-button-label": "Pay",
  "cc-form-error-future-date": "Not a future date",
  "cc-form-error-invalid-date": "Invalid date",
  "cc-form-error-max-length": "{maxLength} characters or less",
  "cc-form-error-min-length": "{minLength} characters or more",
  "cc-form-error-required-field": "This field is required",
  "cc-form-name-on-card-label": "Name on card",
  "checkout-header-description-one-off-payments": "Your payment",
  "checkout-header-description-recurring-payments": "Your subscription",
  close: "Close",
  continue: "Continue",
  "currency-aud": "Australian Dollar",
  "currency-cad": "Canadian Dollar",
  "currency-chf": "Swiss Frank",
  "currency-czk": "Czech Koruna",
  "currency-dkk": "Danish Krone",
  "currency-eur": "Euro",
  "currency-gbp": "British Pound",
  "currency-hkd": "Hong Kong Dollar",
  "currency-huf": "Hungarian Forint",
  "currency-ils": "Israeli New Shekel",
  "currency-jpy": "Japanese Yen",
  "currency-nok": "Norwegian Krone",
  "currency-nzd": "New Zealand Dollar",
  "currency-php": "Philippine Piso",
  "currency-pln": "Polish Złoty",
  "currency-rub": "Russian Ruble",
  "currency-sek": "Swedish Krona",
  "currency-sgd": "Singapore Dollar",
  "currency-usd": "US Dollar",
  "dummy-checkout-disclaimer": "Demo secured and provided by",
  "dummy-checkout-payment-canceled": "Payment canceled",
  "dummy-checkout-payment-expired": "Payment expired",
  "dummy-checkout-payment-failed": "Payment failed",
  "dummy-checkout-payment-successful": "Payment successful",
  "dummy-checkout-photo-credits":
    "Background image by {photographerLink} on {unsplashLink}",
  "dummy-checkout-status-finalize": "Finalize",
  "dummy-checkout-status-select-label": "Select a payment status",
  "dummy-checkout-subscription-canceled": "Subscription unsuccessful",
  "dummy-checkout-subscription-expired": "Subscription unsuccessful",
  "dummy-checkout-subscription-failed": "Subscription failed",
  "dummy-checkout-subscription-successful": "Subscription confirmed",
  "location-austria": "Austria",
  "location-belgium": "Belgium",
  "location-denmark": "Denmark",
  "location-finland": "Finland",
  "location-france": "France",
  "location-germany": "Germany",
  "location-greece": "Greece",
  "location-italy": "Italy",
  "location-netherlands": "The Netherlands",
  "location-norway": "Norway",
  "location-poland": "Poland",
  "location-spain": "Spain",
  "location-sweden": "Sweden",
  "location-switzerland": "Switzerland",
  "name-placeholder": "Your company",
  "no-results-found": "No results found",
  "not-found-button": "Go to homepage",
  "not-found-text":
    "The page you were looking for doesn’t exist. It may have been deleted or moved.",
  "not-found-title": "Page not found",
  "one-off-payments-text":
    "Quickly integrate all major payment methods using one of our free plugins, or build your own payment product with our robust, yet simple API.",
  "one-off-payments-title": "One-off payments",
  "one-remaining-payment-method-tooltip-text":
    "At least one payment method needs to remain enabled.",
  "payment-method-bank-transfer": "Bank Transfer",
  "payment-method-credit-card": "Card",
  "payment-method-gift-cards": "Gift Cards",
  "payment-method-kbc-cbc-button": "KBC/CBC Button",
  "payment-method-klarna-pay-later": "Klarna: Pay Later",
  "payment-method-klarna-slice-it": "Klarna: Slice It",
  "payment-method-sofort": "SOFORT Banking",
  "payment-method-vouchers": "Vouchers",
  "payment-methods-title": "Payment methods",
  "payment-status-canceled": "Canceled",
  "payment-status-expired": "Expired",
  "payment-status-failed": "Failed",
  "payment-status-nav-title": "Choose a payment status",
  "payment-status-paid": "Paid",
  "payment-status-processing": "Processing",
  "payment-type-nav-title": "Choose a payment type",
  "personalize-background-image-clear-button-label": "Reset background image",
  "personalize-background-image-title": "Background",
  "personalize-background-image-value": "Unsplash photo",
  "personalize-currency-title": "Currency",
  "personalize-location-title": "Location",
  "personalize-name-title": "Company name",
  "personalize-title": "Personalize",
  "recurring-payments-text":
    "Enjoy the freedom to create any payment plan that fits your business, so you can effortlessly collect your recurring payments.",
  "recurring-payments-title": "Recurring payments",
  "resources-about-link-text": "About Mollie",
  "resources-docs-link-text": "Read the documentation",
  "resources-get-started-action": "Sign up",
  "resources-get-started-text":
    "Create a Mollie account to start accepting payments on your website or in your app today!",
  "resources-get-started-title": "Get started",
  "resources-help-link-text": "Help Center",
  "resources-packages-link-text": "View our packages & plugins",
  "resources-text":
    "Easily integrate Mollie into your hosted webshop with one of our free plugins or build it yourself using our provided packages.",
  "resources-title": "Resources",
  "sidebar-home-locale-select-title": "Language",
  "sidebar-home-text":
    "Checkout turns a simple, standard payment form into a professional, on-brand experience that inspires trust and drives conversions. All without having to write a single line of code.",
  "start-over": "Start over",
  "test-cards-authentication-title": "Authentication",
  "test-cards-copy-button-label": "Copy test card number",
  "test-cards-decline-title": "Decline",
  "test-cards-numbers-title": "Test cards",
  "test-cards-provider-label": "Provider",
  "test-cards-success-title": "Success",
  title: "Explore Mollie Checkout",
};
