import { IntlProvider } from "react-intl";
import styles from "./styles.module.css";
import Sidebar from "../Sidebar";
import Preview from "../Preview";
import ScrollToTop from "../ScrollToTop";
import { getIntlProviderProps, getLocaleFromAppLanguage } from "../../i18n";
import { StoreProvider } from "../Store";
import GlobalMetaTags from "../GlobalMetaTags";
import NotFound from "../NotFound";
import useAppLanguage from "../../hooks/useAppLanguage";

interface Props {
  statusCode?: number;
}

function App(props: Props) {
  const appLanguage = useAppLanguage();

  return (
    <IntlProvider
      {...getIntlProviderProps(getLocaleFromAppLanguage(appLanguage))}
    >
      <StoreProvider>
        <div className={styles.app}>
          <GlobalMetaTags />
          <ScrollToTop />
          {props.statusCode === 404 ? (
            <NotFound />
          ) : (
            <>
              <Sidebar />
              <Preview />
            </>
          )}
        </div>
      </StoreProvider>
    </IntlProvider>
  );
}

export default App;
