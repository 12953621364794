import clsx from "clsx";
import React, { Dispatch, SetStateAction } from "react";
import usePrevious from "../../hooks/usePrevious";
import { useRect } from "@reach/rect";
import styles from "./styles.module.css";

interface State {
  selectedIndex: number;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  activeRect: DOMRect | null;
  setActiveRect: Dispatch<SetStateAction<any>>;
}

const TabsContext = React.createContext<Partial<State>>({});

interface TabProps {
  children: React.ReactNode;
  index?: number;
}

function Tab({ index, ...props }: TabProps) {
  const { selectedIndex, setSelectedIndex, setActiveRect } =
    React.useContext(TabsContext);
  const isSelected = selectedIndex === index;
  const ref = React.useRef<HTMLButtonElement>(null);
  const rect = useRect(ref, { observe: isSelected });

  React.useLayoutEffect(() => {
    if (typeof setActiveRect === "function" && isSelected) {
      setActiveRect(rect);
    }
  }, [isSelected, rect, setActiveRect]);

  return (
    <button
      ref={ref}
      className={clsx(styles.tab, isSelected && styles.selected)}
      onClick={() => {
        if (
          typeof setSelectedIndex !== "undefined" &&
          typeof index === "number"
        ) {
          setSelectedIndex(index);
        }
      }}
      {...props}
    />
  );
}

interface TabListProps {
  children: React.ReactNode;
}

/**
 * Clones a passed element and accepts optional props. This is useful
 * if you want to iterate over children and pass additional props to
 * them. Uses the `isValidElement` type guard to satisfy TypeScript.
 */
function cloneValidElement<Props>(
  element: React.ReactElement<Props> | React.ReactNode,
  props?: Partial<Props> & React.Attributes,
  ...children: React.ReactNode[]
): React.ReactElement<Props> | React.ReactNode {
  return React.isValidElement(element)
    ? React.cloneElement(element, props, ...children)
    : element;
}

interface IndicatorStyles {
  left?: number;
  width?: number;
}

function getIndicatorStyles(config: {
  containerRect?: DOMRect | null;
  itemRect?: DOMRect | null;
}): IndicatorStyles {
  const horizontalPadding = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue(
      "--tablist-padding"
    )
  );
  let styles: IndicatorStyles = {};

  if (
    typeof config.itemRect !== "undefined" &&
    typeof config.containerRect !== "undefined" &&
    config.itemRect?.left &&
    config.itemRect?.width &&
    config.containerRect?.left
  ) {
    styles["left"] =
      config.itemRect.left - config.containerRect.left + horizontalPadding;
    styles["width"] = config.itemRect.width - horizontalPadding * 2;
  }

  return styles;
}

function TabList({ children, ...props }: TabListProps) {
  const { activeRect } = React.useContext(TabsContext);
  const ref = React.useRef<HTMLDivElement>(null);
  const rect = useRect(ref);

  return (
    <div ref={ref} className={styles.tablist} {...props}>
      {React.Children.map(children, (child, i) => {
        return cloneValidElement(child, {
          index: i,
        });
      })}

      <span
        className={styles.indicator}
        style={getIndicatorStyles({
          containerRect: rect,
          itemRect: activeRect,
        })}
      />
    </div>
  );
}

interface TabsProps {
  children: React.ReactNode;
  onChange?: (index: number) => void;
  defaultIndex?: number;
}

function Tabs({ onChange, defaultIndex = 0, ...props }: TabsProps) {
  const [selectedIndex, setSelectedIndex] = React.useState(defaultIndex);
  const [activeRect, setActiveRect] = React.useState<DOMRect | null>(null);
  const previousIndex = usePrevious(selectedIndex);

  React.useEffect(() => {
    if (
      typeof onChange === "function" &&
      typeof previousIndex !== "undefined" &&
      previousIndex !== selectedIndex
    ) {
      onChange(selectedIndex);
    }
  }, [onChange, previousIndex, selectedIndex]);

  return (
    <TabsContext.Provider
      value={{ selectedIndex, setSelectedIndex, activeRect, setActiveRect }}
      {...props}
    />
  );
}

export { Tabs, TabList, Tab };
