import { defineMessage } from "react-intl";
import applePayIcon from "../svg/apple-pay.svg";
import bancontactIcon from "../svg/bancontact.svg";
import bankTransferIcon from "../svg/bank-transfer.svg";
import belfiusIcon from "../svg/belfius.svg";
import creditCardIcon from "../svg/credit-card.svg";
import epsIcon from "../svg/eps.svg";
import giftCardsIcon from "../svg/gift-card.svg";
import giropayIcon from "../svg/giropay.svg";
import iDealIcon from "../svg/ideal.svg";
import kbcCbcIcon from "../svg/kbc-cbc.svg";
import klarnaIcon from "../svg/klarna.svg";
import payPalIcon from "../svg/paypal.svg";
import paysafecardIcon from "../svg/paysafecard.svg";
import przelewy24Icon from "../svg/przelewy24.svg";
import vouchersIcon from "../svg/voucher.svg";
import { PaymentMethod } from "../types";

export interface PaymentMethods {
  creditCard: PaymentMethod;
  applePay: PaymentMethod;
  iDeal: PaymentMethod;
  bancontact: PaymentMethod;
  eps: PaymentMethod;
  giropay: PaymentMethod;
  kbcCbc: PaymentMethod;
  belfius: PaymentMethod;
  vouchers: PaymentMethod;
  giftCards: PaymentMethod;
  paysafecard: PaymentMethod;
  sofort: PaymentMethod;
  klarnaPayLater: PaymentMethod;
  klarnaSliceIt: PaymentMethod;
  przelewy24: PaymentMethod;
  payPal: PaymentMethod;
  bankTransfer: PaymentMethod;
}

export const paymentMethods: PaymentMethods = {
  creditCard: {
    id: "CC",
    name: defineMessage({
      id: "payment-method-credit-card",
      defaultMessage: "Credit Card",
    }),
    supports: { oneOffPayments: true, recurringPayments: true },
    enabled: true,
    icon: creditCardIcon,
    redirectTo: "credit-card",
  },
  applePay: {
    id: "AP",
    name: "Apple Pay",
    supports: { oneOffPayments: true, recurringPayments: true },
    enabled: true,
    icon: applePayIcon,
  },
  iDeal: {
    id: "ID",
    name: "iDeal",
    supports: { oneOffPayments: true, recurringPayments: true },
    enabled: true,
    icon: iDealIcon,
    redirectTo: "ideal",
  },
  bancontact: {
    id: "BC",
    name: "Bancontact",
    supports: { oneOffPayments: true, recurringPayments: true },
    enabled: true,
    icon: bancontactIcon,
  },
  eps: {
    id: "EP",
    name: "EPS",
    supports: { oneOffPayments: true, recurringPayments: true },
    enabled: true,
    icon: epsIcon,
  },
  giropay: {
    id: "GI",
    name: "Giropay",
    supports: { oneOffPayments: true, recurringPayments: true },
    enabled: true,
    icon: giropayIcon,
  },
  kbcCbc: {
    id: "KB",
    name: defineMessage({
      id: "payment-method-kbc-cbc-button",
      defaultMessage: "KBC/CBC Button",
    }),
    supports: { oneOffPayments: true, recurringPayments: true },
    enabled: true,
    icon: kbcCbcIcon,
  },
  belfius: {
    id: "BP",
    name: "Belfius Pay Button",
    supports: { oneOffPayments: true, recurringPayments: true },
    enabled: true,
    icon: belfiusIcon,
  },
  vouchers: {
    id: "VO",
    name: defineMessage({
      id: "payment-method-vouchers",
      defaultMessage: "Vouchers",
    }),
    supports: { oneOffPayments: true, recurringPayments: false },
    enabled: true,
    icon: vouchersIcon,
  },
  giftCards: {
    id: "GC",
    name: defineMessage({
      id: "payment-method-gift-cards",
      defaultMessage: "Gift Cards",
    }),
    supports: { oneOffPayments: true, recurringPayments: false },
    enabled: true,
    icon: giftCardsIcon,
  },
  paysafecard: {
    id: "PS",
    name: "paysafecard",
    supports: { oneOffPayments: true, recurringPayments: false },
    enabled: true,
    icon: paysafecardIcon,
  },
  sofort: {
    id: "SO",
    name: defineMessage({
      id: "payment-method-sofort",
      defaultMessage: "SOFORT Banking",
    }),
    supports: { oneOffPayments: true, recurringPayments: true },
    enabled: true,
    icon: klarnaIcon,
  },
  klarnaPayLater: {
    id: "KL",
    name: defineMessage({
      id: "payment-method-klarna-pay-later",
      defaultMessage: "Klarna: Pay Later",
    }),
    supports: { oneOffPayments: true, recurringPayments: false },
    enabled: true,
    icon: klarnaIcon,
  },
  klarnaSliceIt: {
    id: "KS",
    name: defineMessage({
      id: "payment-method-klarna-slice-it",
      defaultMessage: "Klarna: Slice It",
    }),
    supports: { oneOffPayments: true, recurringPayments: false },
    enabled: true,
    icon: klarnaIcon,
  },
  przelewy24: {
    id: "PR",
    name: "Przelewy24",
    supports: { oneOffPayments: true, recurringPayments: false },
    enabled: true,
    icon: przelewy24Icon,
  },
  payPal: {
    id: "PP",
    name: "PayPal",
    supports: { oneOffPayments: true, recurringPayments: true },
    enabled: true,
    icon: payPalIcon,
  },
  bankTransfer: {
    id: "BT",
    name: defineMessage({
      id: "payment-method-bank-transfer",
      defaultMessage: "Bank Transfer",
    }),
    supports: { oneOffPayments: true, recurringPayments: false },
    enabled: true,
    icon: bankTransferIcon,
  },
};

export const defaultPaymentMethods: PaymentMethod[] = [
  paymentMethods.creditCard,
  paymentMethods.klarnaPayLater,
  paymentMethods.klarnaSliceIt,
  paymentMethods.payPal,
  paymentMethods.iDeal,
  paymentMethods.sofort,
  paymentMethods.bancontact,
  paymentMethods.eps,
  paymentMethods.giropay,
  paymentMethods.przelewy24,
  paymentMethods.kbcCbc,
  paymentMethods.belfius,
  paymentMethods.bankTransfer,
  paymentMethods.paysafecard,
  paymentMethods.giftCards,
  paymentMethods.vouchers,
  paymentMethods.applePay,
];
