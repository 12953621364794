import { Country } from "../types";
import { defineMessage } from "react-intl";

export const countries: Country[] = [
  {
    code: "NL",
    name: defineMessage({
      id: "location-netherlands",
      defaultMessage: "The Netherlands",
    }),
  },
  {
    code: "BE",
    name: defineMessage({
      id: "location-belgium",
      defaultMessage: "Belgium",
    }),
  },
  {
    code: "DE",
    name: defineMessage({
      id: "location-germany",
      defaultMessage: "Germany",
    }),
  },
  {
    code: "AT",
    name: defineMessage({
      id: "location-austria",
      defaultMessage: "Austria",
    }),
  },
  {
    code: "CH",
    name: defineMessage({
      id: "location-switzerland",
      defaultMessage: "Switzerland",
    }),
  },
  {
    code: "FR",
    name: defineMessage({
      id: "location-france",
      defaultMessage: "France",
    }),
  },
  {
    code: "ES",
    name: defineMessage({
      id: "location-spain",
      defaultMessage: "Spain",
    }),
  },
  {
    code: "DK",
    name: defineMessage({
      id: "location-denmark",
      defaultMessage: "Denmark",
    }),
  },
  {
    code: "NO",
    name: defineMessage({
      id: "location-norway",
      defaultMessage: "Norway",
    }),
  },
  {
    code: "FI",
    name: defineMessage({
      id: "location-finland",
      defaultMessage: "Finland",
    }),
  },
  {
    code: "SE",
    name: defineMessage({
      id: "location-sweden",
      defaultMessage: "Sweden",
    }),
  },
  {
    code: "IT",
    name: defineMessage({
      id: "location-italy",
      defaultMessage: "Italy",
    }),
  },
  {
    code: "GR",
    name: defineMessage({
      id: "location-greece",
      defaultMessage: "Greece",
    }),
  },
  {
    code: "PL",
    name: defineMessage({
      id: "location-poland",
      defaultMessage: "Poland",
    }),
  },
  {
    code: "UK",
    name: defineMessage({
      id: "location-uk",
      defaultMessage: "United Kingdom",
    }),
  },
];
