import { PaymentStatus } from "../types";
import { defineMessage } from "react-intl";

interface PaymentStatuses {
  processing: PaymentStatus;
  paid: PaymentStatus;
  canceled: PaymentStatus;
  expired: PaymentStatus;
  failed: PaymentStatus;
}

export const paymentStatuses: PaymentStatuses = {
  processing: {
    id: "processing",
    name: defineMessage({
      id: "payment-status-processing",
      defaultMessage: "Processing",
    }),
  },
  paid: {
    id: "paid",
    name: defineMessage({
      id: "payment-status-paid",
      defaultMessage: "Paid",
    }),
  },
  canceled: {
    id: "canceled",
    name: defineMessage({
      id: "payment-status-canceled",
      defaultMessage: "Canceled",
    }),
  },
  expired: {
    id: "expired",
    name: defineMessage({
      id: "payment-status-expired",
      defaultMessage: "Expired",
    }),
  },
  failed: {
    id: "failed",
    name: defineMessage({
      id: "payment-status-failed",
      defaultMessage: "Failed",
    }),
  },
};
