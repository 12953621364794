import clsx from "clsx";
import styles from "./styles.module.css";

interface Props {
  children?: React.ReactNode;
  className?: string;
  alignment?: "leading" | "center" | "trailing" | "space-between";
}

function Toolbar({ className, alignment = "leading", ...props }: Props) {
  return (
    <div
      className={clsx(styles.toolbar, styles[alignment], className)}
      {...props}
    />
  );
}

export default Toolbar;
