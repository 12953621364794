import clsx from "clsx";
import styles from "./styles.module.css";

interface Props {
  children?: React.ReactNode;
  duration?: number;
  delay?: number;
  scale?: boolean;
}

function FadeIn({
  children,
  duration = 200,
  delay = 0,
  scale = false,
  ...props
}: Props) {
  return (
    <div
      style={{
        animationDuration: duration + "ms",
        animationDelay: delay + "ms",
      }}
      className={clsx(
        styles.animated,
        styles[scale ? "fade-in-scale" : "fade-in"]
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export default FadeIn;
