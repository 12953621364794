import { State, Action } from "./types";
import { getPaymentMethods } from "./utils";

function reducer(state: State, action: Action<any>): State {
  switch (action.type) {
    case "SET_PAYMENT_TYPE":
      return {
        ...state,
        paymentType: action.payload.paymentType,
        // Set payment methods based on chosen type.
        paymentMethods: getPaymentMethods({
          paymentType: action.payload.paymentType,
          methodsQueryParam: action.payload.methods,
          countryCode: state.location,
        }),
      };

    case "SET_NAME":
      return {
        ...state,
        name: action.payload,
      };

    case "SET_PAYMENT_STATUS":
      return {
        ...state,
        paymentStatus: action.payload,
      };

    case "SET_LOADING_PAYMENT":
      return {
        ...state,
        loadingPayment: action.payload,
      };

    case "SET_SELECTED_PAYMENT_METHOD":
      return {
        ...state,
        selectedPaymentMethod: action.payload,
      };

    case "SET_LOCATION":
      return {
        ...state,
        location: action.payload.location,
        // Set payment methods based on chosen location.
        paymentMethods: getPaymentMethods({
          paymentType: state.paymentType,
          methodsQueryParam: action.payload.methods,
          countryCode: action.payload.location,
        }),
      };

    case "SET_CURRENCY":
      return {
        ...state,
        currency: action.payload,
      };

    case "ENABLE_PAYMENT_METHOD":
      return {
        ...state,
        paymentMethods: state.paymentMethods.map((method) => {
          if (method.id === action.payload)
            return {
              ...method,
              enabled: true,
            };
          return method;
        }),
      };

    case "DISABLE_PAYMENT_METHOD":
      return {
        ...state,
        paymentMethods: state.paymentMethods.map((method) => {
          if (method.id === action.payload)
            return {
              ...method,
              enabled: false,
            };
          return method;
        }),
      };

    case "SET_VIEW":
      return {
        ...state,
        view: action.payload,
      };

    case "SET_BACKGROUND_IMAGE":
      return {
        ...state,
        backgroundImage: action.payload,
      };

    default:
      return state;
  }
}

export default reducer;
