import { useStore } from "../Store";
import styles from "./styles.module.css";
import { ReactComponent as FallbackLogo } from "../../svg/mollie.svg";
import FadeIn from "../FadeIn";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import PreloadAssets from "./PreloadAssets";
import {
  getUnsplashPhotographerUrl,
  getUnsplashPhotographerName,
  getUnsplashUrl,
} from "../../utils";
import { FormattedMessage } from "react-intl";
import { SmallText } from "../Text";
import BackgroundImage from "./BackgroundImage";

interface Props {
  children?: React.ReactNode;
}

function DummyCheckout(props: Props) {
  const store = useStore();
  const { pathname } = useLocation();
  const isEnabled = /one-off-payments|recurring-payments/.test(pathname);

  return (
    <div className={clsx(styles.container, styles[`${store.view}-view`])}>
      <BackgroundImage />
      <PreloadAssets />

      {store.view === "desktop" && (
        <FadeIn delay={200}>
          <header className={styles.logo}>
            <FallbackLogo />
          </header>
        </FadeIn>
      )}

      <FadeIn delay={200} scale>
        <div className={clsx(styles.checkout, !isEnabled && styles.disabled)}>
          {props.children}
        </div>
      </FadeIn>

      {store.backgroundImage && (
        <FadeIn delay={200}>
          <footer className={styles["photo-credits"]}>
            <SmallText>
              <FormattedMessage
                id="dummy-checkout-photo-credits"
                defaultMessage="Background image by {photographerLink} on {unsplashLink}"
                values={{
                  photographerLink: (
                    <a href={getUnsplashPhotographerUrl(store.backgroundImage)}>
                      {getUnsplashPhotographerName(store.backgroundImage)}
                    </a>
                  ),
                  unsplashLink: <a href={getUnsplashUrl()}>Unsplash</a>,
                }}
              />
            </SmallText>
          </footer>
        </FadeIn>
      )}
    </div>
  );
}

export default DummyCheckout;
