// Auto generated file. Do no change. Go to Crowdin to update the translations and run './node_modules/.bin/mollie-crowdin download' to update this file.
export default {
  "background-image-picker-input-placeholder": "Unsplash durchsuchen",
  "cc-form-card-expiry-date-label": "Ablaufdatum",
  "cc-form-card-number-label": "Kartennummer",
  "cc-form-card-submit-button-label": "Bezahlen",
  "cc-form-error-future-date": "Kein zukünftiges Datum",
  "cc-form-error-invalid-date": "Ungültiges Datum",
  "cc-form-error-max-length": "{maxLength} Zeichen oder weniger",
  "cc-form-error-min-length": "{minLength} oder mehr Zeichen",
  "cc-form-error-required-field": "Dies ist ein Pflichtfeld",
  "cc-form-name-on-card-label": "Name auf der Karte",
  "checkout-header-description-one-off-payments": "Deine Zahlung",
  "checkout-header-description-recurring-payments": "Dein Abonnement",
  close: "Schließen",
  continue: "Weiter",
  "currency-aud": "Australische Dollar",
  "currency-cad": "Kanadische Dollar",
  "currency-chf": "Schweizer Franken",
  "currency-czk": "Tschechische Krone",
  "currency-dkk": "Dänische Krone",
  "currency-eur": "Euro",
  "currency-gbp": "Britisches Pfund",
  "currency-hkd": "Hongkong-Dollar",
  "currency-huf": "Ungarische Forint",
  "currency-ils": "Neue israelische Schekel",
  "currency-jpy": "Japanische Yen",
  "currency-nok": "Norwegische Krone",
  "currency-nzd": "Neuseeland-Dollar",
  "currency-php": "Philippinische Piso",
  "currency-pln": "Polnische Złoty",
  "currency-rub": "Russische Rubel",
  "currency-sek": "Schwedische Krone",
  "currency-sgd": "Singapur-Dollar",
  "currency-usd": "US-Dollar",
  "dummy-checkout-disclaimer": "Demo gesichert und bereitgestellt von",
  "dummy-checkout-payment-canceled": "Zahlung storniert",
  "dummy-checkout-payment-expired": "Zahlung abgelaufen",
  "dummy-checkout-payment-failed": "Zahlung fehlgeschlagen",
  "dummy-checkout-payment-successful": "Zahlung erfolgreich",
  "dummy-checkout-photo-credits":
    "Hintergrundbild von {photographerLink} auf {unsplashLink}",
  "dummy-checkout-status-finalize": "Abschließen",
  "dummy-checkout-status-select-label": "Wähle einen Zahlungsstatus",
  "dummy-checkout-subscription-canceled": "Abonnement fehlgeschlagen",
  "dummy-checkout-subscription-expired": "Abonnement fehlgeschlagen",
  "dummy-checkout-subscription-failed": "Abonnement gescheitert",
  "dummy-checkout-subscription-successful": "Abonnement bestätigt",
  "location-austria": "Österreich",
  "location-belgium": "Belgien",
  "location-denmark": "Dänemark",
  "location-finland": "Finnland",
  "location-france": "Frankreich",
  "location-germany": "Deutschland",
  "location-greece": "Griechenland",
  "location-italy": "Italien",
  "location-netherlands": "Niederlande",
  "location-norway": "Norwegen",
  "location-poland": "Polen",
  "location-spain": "Spanien",
  "location-sweden": "Schweden",
  "location-switzerland": "Schweiz",
  "name-placeholder": "Dein Unternehmen",
  "no-results-found": "Keine Ergebnisse gefunden",
  "not-found-button": "Gehe zur Homepage",
  "not-found-text":
    "Die Seite, nach der du suchst, existiert nicht. Sie wurde möglicherweise gelöscht oder verschoben.",
  "not-found-title": "Seite nicht gefunden",
  "one-off-payments-text":
    "Integrieren Sie schnell alle wichtigen Zahlungsmethoden mit einem unserer kostenlosen Plugins oder erstellen Sie Ihr eigenes Zahlungsprodukt mit unserer robusten und einfachen API.",
  "one-off-payments-title": "Einmalige Zahlungen",
  "one-remaining-payment-method-tooltip-text":
    "Mindestens eine Zahlungsmethode muss aktiviert bleiben.",
  "payment-method-bank-transfer": "Überweisung",
  "payment-method-credit-card": "Karte",
  "payment-method-gift-cards": "Geschenkkarten",
  "payment-method-kbc-cbc-button": "KBC/CBC Zahlungsbutton",
  "payment-method-klarna-pay-later": "Klarna: Rechnungskauf",
  "payment-method-klarna-slice-it": "Klarna: Ratenzahlung",
  "payment-method-sofort": "SOFORT Überweisung",
  "payment-method-vouchers": "Gutscheine",
  "payment-methods-title": "Zahlungsmethoden",
  "payment-status-canceled": "Abgebrochen",
  "payment-status-expired": "Abgelaufen",
  "payment-status-failed": "Fehlgeschlagen",
  "payment-status-nav-title": "Wähle einen Zahlungsstatus",
  "payment-status-paid": "Bezahlt",
  "payment-status-processing": "In Bearbeitung",
  "payment-type-nav-title": "Zahlungstyp wählen",
  "personalize-background-image-clear-button-label":
    "Hintergrundbild zurücksetzen",
  "personalize-background-image-title": "Hintergrund",
  "personalize-background-image-value": "Unsplash Foto",
  "personalize-currency-title": "Währung",
  "personalize-location-title": "Standort",
  "personalize-name-title": "Firmenname",
  "personalize-title": "Personalisieren",
  "recurring-payments-text":
    "Genießen Sie die Freiheit, einen beliebigen Zahlungsplan zu erstellen, der zu Ihrem Unternehmen passt, damit Sie Ihre wiederkehrenden Zahlungen mühelos einsammeln können.",
  "recurring-payments-title": "Wiederkehrende Zahlungen",
  "resources-about-link-text": "Über Mollie",
  "resources-docs-link-text": "Zur Dokumentation",
  "resources-get-started-action": "Melde dich an",
  "resources-get-started-text":
    "Erstelle einen Account, um noch heute Zahlungen auf deiner Website oder in deiner App zu akzeptieren!",
  "resources-get-started-title": "Jetzt loslegen",
  "resources-help-link-text": "Hilfe-Center",
  "resources-packages-link-text": "Unsere Pakete & Plugins",
  "resources-text":
    "Integriere Mollie mit einem unserer kostenlosen Plugins ganz einfach in deinem gehosteten Webshop oder baue es selbst mit unseren bereitgestellten Paketen auf.",
  "resources-title": "Ressourcen",
  "sidebar-home-locale-select-title": "Sprache",
  "sidebar-home-text":
    "Checkout verwandelt ein einfaches Zahlungsformular in ein professionelles Markenerlebnis, das Vertrauen schafft und Conversions fördert. Alles ohne eine einzige Zeile Code schreiben zu müssen.",
  "start-over": "Zurück zum Start",
  "test-cards-authentication-title": "Authentifizierung",
  "test-cards-copy-button-label": "Testkartennummer kopieren",
  "test-cards-decline-title": "Abgelehnt",
  "test-cards-numbers-title": "Test-Karten",
  "test-cards-provider-label": "Anbieter",
  "test-cards-success-title": "Erfolgreich",
  title: "Entdecke Mollie Checkout",
};
