import { useStore } from "../../Store";
import styles from "./styles.module.css";
import { getUnsplashPhotoUrl } from "../../../utils";

function BackgroundImage() {
  const store = useStore();

  return (
    <div
      className={styles.background}
      style={
        store.view === "desktop" && store.backgroundImage
          ? {
              backgroundImage: `url(${getUnsplashPhotoUrl(
                store.backgroundImage
              )})`,
            }
          : undefined
      }
    />
  );
}

export default BackgroundImage;
