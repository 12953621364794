import React from "react";
import ReactDOM from "react-dom";
import "@mollie/react-components/dist/styles.css";
import "./normalize.css";
import "./styles.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import App from "./components/App";
import { defaultAppLanguage, appLanguages } from "./i18n";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { getEnvironmentByHostname } from "./utils";

Sentry.init({
  dsn: "https://814fc4bc23464274b453985633dbb391@o29109.ingest.sentry.io/5873775",
  integrations: [new Integrations.BrowserTracing()],
  environment: getEnvironmentByHostname(window.location.hostname),

  /**
   * Setting tracesSampleRate to 1.0 will capture 100% of transactions
   * for performance monitoring. Turning this off here since
   * transactions aren’t part of our Sentry plan at the moment.
   *
   * @see https://develop.sentry.dev/sdk/performance/#tracessamplerate
   */
  tracesSampleRate: 0.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={`/${defaultAppLanguage()}`} replace />}
        />
        {appLanguages.map((language) => (
          <React.Fragment key={language}>
            <Route path={`/${language}/*`} element={<App />} />
            <Route
              path={`/${language}/not-found`}
              element={<App statusCode={404} />}
            />
          </React.Fragment>
        ))}
        <Route
          path="/not-found"
          element={
            <Navigate to={`/${defaultAppLanguage()}/not-found`} replace />
          }
        />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
