import React from "react";
import { ActivityIndicator } from "@mollie/react-components";
import { Routes, Route } from "react-router-dom";
import styles from "./styles.module.css";
import DummyCheckout from "../DummyCheckout";
import { ReactComponent as Phone } from "../../svg/phone.svg";
import { ReactComponent as Laptop } from "../../svg/laptop.svg";
import Toolbar from "../Toolbar";
import { Tabs, TabList, Tab } from "../Tabs";
import FadeIn from "../FadeIn";
import { setDesktopView, setMobileView, useDispatch } from "../Store";
import { paymentTypes } from "../../constants";

const Status = React.lazy(() => import("../DummyCheckout/Status"));
const PaymentMethods = React.lazy(
  () => import("../DummyCheckout/PaymentMethods")
);
const Banks = React.lazy(() => import("../DummyCheckout/Banks"));
const CreditCardForm = React.lazy(
  () => import("../DummyCheckout/CreditCardForm")
);

function Preview() {
  const dispatch = useDispatch();

  return (
    <main className={styles.preview}>
      <Toolbar alignment="center">
        <FadeIn>
          <Tabs
            defaultIndex={1}
            onChange={(selectedIndex) => {
              if (selectedIndex === 0) {
                dispatch(setMobileView());
              } else {
                dispatch(setDesktopView());
              }
            }}
          >
            <TabList>
              <Tab>
                <Phone />
              </Tab>
              <Tab>
                <Laptop />
              </Tab>
            </TabList>
          </Tabs>
        </FadeIn>
      </Toolbar>

      <DummyCheckout>
        <React.Suspense
          fallback={<ActivityIndicator size="large" delay={1000} />}
        >
          <Routes>
            <Route path="/" element={<PaymentMethods />} />
            {paymentTypes.map((type) => (
              <React.Fragment key={type}>
                <Route path={`/${type}`} element={<PaymentMethods />} />
                <Route path={`/${type}/ideal`} element={<Banks />} />
                <Route
                  path={`/${type}/credit-card`}
                  element={<CreditCardForm />}
                />
                <Route
                  path={`/${type}/payment/:paymentStatus`}
                  element={<Status />}
                />
                <Route
                  path={`/${type}/ideal/payment/:paymentStatus`}
                  element={<Status />}
                />
                <Route
                  path={`/${type}/credit-card/payment/:paymentStatus`}
                  element={<Status />}
                />
                <Route
                  path={`/${type}/credit-card/payment/:paymentStatus/:paymentOutcome`}
                  element={<Status />}
                />
              </React.Fragment>
            ))}
          </Routes>
        </React.Suspense>
      </DummyCheckout>
    </main>
  );
}

export default Preview;
