import { FormattedMessage } from "react-intl";
import { H1 } from "../Heading";
import { VStack } from "../Stack";
import { Text } from "../Text";
import { Button } from "@mollie/react-components";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

function NotFound() {
  return (
    <div className={styles.container}>
      <VStack spacing="md">
        <H1>
          <FormattedMessage
            id="not-found-title"
            defaultMessage="Page not found"
          />
        </H1>

        <VStack spacing="lg">
          <Text>
            <FormattedMessage
              id="not-found-text"
              defaultMessage="The page you were looking for doesn’t exist. It may have been deleted or moved."
            />
          </Text>

          <footer>
            <Button as={Link} to="/">
              <FormattedMessage
                id="not-found-button"
                defaultMessage="Go to homepage"
              />
            </Button>
          </footer>
        </VStack>
      </VStack>
    </div>
  );
}

export default NotFound;
